import { render, staticRenderFns } from "./NotBookmark.vue?vue&type=template&id=637632de&scoped=true&functional=true&"
import script from "./NotBookmark.vue?vue&type=script&lang=js&"
export * from "./NotBookmark.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "637632de",
  null
  
)

export default component.exports