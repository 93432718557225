<template lang="pug">
  div.empty-bookmarks
    p.empty-bookmarks--text.center(v-if="full") {{ 'pages.builder.collect_badges_from_the_explorer' | translate }}
    div.empty-bookmarks--grid(v-else)
      img-not-bookmark.empty-bookmarks--img
      p.empty-bookmarks--text
        span.d-block {{ $t(`pages.builder.you_have_no_bookmarks_yet`) }}
        span.d-block.w-196 {{ $t(`pages.builder.discover_new_skills`) }}
      button.empty-bookmarks--button(@click="$emit('click')")
        | {{$t(`ui.buttons.open_explorer`)}}
</template>

<script>
import ImgNotBookmark from '@/components/svg/images/NotBookmark.vue'
export default {
  name: 'empty-bookmarks',
  components: {
    ImgNotBookmark
  },
  props: {
    full: {
      type: Boolean,
      defaults: false
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-bookmarks {
  height: 100%;
  display: flex;

  &--grid {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }
  ::v-deep &--img {
    display: block;
    width: 160px;
    height: 160px;
  }
  &--text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: $body-text-2;
    text-align: center;
    margin: 0;
      &.center{
        align-self: center;
      }
    .w-196 {
      max-width: 196px;
      margin: 0 auto;
    }
  }
  &--button {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: $color-dark-green;
    padding: 8px 16px;
    border: 1px solid $color-dark-green;
    border-radius: 4px;
    background: none;
    &:hover {
      border: 1px solid $color-primary--hover;
      color: $color-primary--hover;
    }
  }
}
</style>
